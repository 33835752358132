// Detects the timezone based on the user's browser, and sets it in a cookie for
// usage by the server.

import $ from 'jquery';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';

$(document).ready(() => {
  const guessedTz = moment.tz.guess();

  if (guessedTz && typeof guessedTz === 'string') {
    // Sometimes, the browser timezone might be set as something like 'Etc/GMT+5',
    // and in cookies a '+' is interpreted as a space, so it needs to be converted to '%2B'.
    // Rails then reads this as `Etc/GMT 5` and may throw an error because this is not a valid timezone.
    //
    // The `write` function below is a copy of what js-cookie does internally,
    // but with `|2B` (the URI encoded `+` character) removed from the .replace() regex.
    // Reference link for original:
    // https://github.com/js-cookie/js-cookie/blob/8b70250875f7e07445b6a457f9c2474ead4cba44/src/js.cookie.js#L71
    const cookiesWithEncodedPlus = Cookies.withConverter({
      // skipcq: JS-0240
      write: function(value) {
        return (
          // Encode all characters according to the "encodeURIComponent" spec
          encodeURIComponent(value)
            // Revert the characters that are unnecessarly encoded but are
            // allowed in a cookie value, with the exception of `+` (`%2B`)
            .replace(
              /%(23|24|26|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
              decodeURIComponent
            )
        );
      }
    });

    cookiesWithEncodedPlus.set('browser.timezone', guessedTz, {
      expires: 365,
      path: '/'
    });
  }
});
